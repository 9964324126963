import React from "react";
import successCpns_1 from "../../../assets/success_story/cpns/success_story_bimbel_cpns_pppk.jpeg";
import successCpns_2 from "../../../assets/success_story/cpns/success_story_bimbel_cpns_pppk_2.jpeg";

const SuccessStoryCpns = () => {
  return (
    <React.Fragment>
      <div className="box-success-story">
        <h1 className="title-success-story">SUCCESS STORY</h1>
        <div className="parent-success-story">
          <img
            className="child-success-story"
            src={successCpns_1}
            alt="Bimbel Privat UTBK SNBT Terbaik - Edumatrix Indonesia"
          />
          <img
            className="child-success-story"
            src={successCpns_2}
            alt="Bimbel Privat UTBK SNBT Terbaik - Edumatrix Indonesia"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SuccessStoryCpns;
